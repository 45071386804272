import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import http from './utils/request'
import common from './utils/common'
import constant from './utils/constant'
import mavonEditor from 'mavon-editor'
import vueEsign from 'vue-esign'
//引入js
//import './utils/live2d'
import './utils/title'
//引入css
import './assets/css/joe.index.min.css'
import './assets/css/APlayer.min.css'
import './assets/css/joe.responsive.min.css'
import './assets/css/joe.global.min.css'
import './assets/css/joe.normalize.min.css'
import './assets/css/bigimg.css'
import './assets/css/joe.mode.min.css'
import './assets/css/animation.css'
import './assets/css/index.css'
import './assets/css/tocbot.css'
import './assets/css/color.css'
// import './assets/css/markdown-highlight.css'
import './assets/css/font-awesome.min.css'
import 'mavon-editor/dist/css/index.css'
//点击涟漪效果
import Ripple from 'vue-ripple-directive'
import {vueBaberrage} from 'vue-baberrage'

Ripple.color = 'var(--rippleColor)'
Vue.directive("ripple", Ripple)
Vue.use(ElementUI)
Vue.use(vueBaberrage)
Vue.use(mavonEditor)
Vue.use(vueEsign)

Vue.prototype.$http = http
Vue.prototype.$common = common
Vue.prototype.$constant = constant

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

/*
/!**
 * 检测到调试时进行的操作
 *!/
let onDebug = function () {
  document.write('检测到非法调试！请停止调试后刷新本页面！');
};

/!**
 * 通过 debugger 时间检测
 *
 * 突破方法：禁用 debugger
 *!/
setInterval(function () {
  setTimeout(function () {
    let st, et;
    st = new Date().getTime();
    debugger;
    et = new Date().getTime();
    if ((et - st) > 1000) {
      onDebug();
    }
  }, 0);
}, 1000);



/!**
 * 监控 F12的按下
 *
 * 没啥用，还有禁止右键菜单，更加没啥用
 *!/
document.onkeydown = document.onkeyup = document.onkeypress = function (event) {
  const e = event || window.event || arguments.callee.caller.arguments[0];

  if (e && e.keyCode == 123) {
    onDebug();
  }
};


/!**
 * 当dom被发送至控制台时(例如console.log)
 * 浏览器会自动通过该dom的getter()获取该 dom 的id
 * 所以我们可以创建一个dom，然后发送到控制台，并且重定义其getter()
 *
 * 缺点：这招对火狐没用
 *!/
let div = document.createElement('div');
Object.defineProperty(div, "id", {
  get: () => {
    clearInterval(loop);
    onDebug();
  }
});
let loop = setInterval(() => {
  setTimeout(() => {
    console.log(div);
    console.clear();
  }, 0);
}, 1000);

/!**
 * 上面那个方法支持谷歌而不支持火狐
 * 这次来个支持火狐不支持谷歌的
 *!/
let firefox_test = /./;
firefox_test.toString = function () {
  onDebug();
}

if (navigator.userAgent.match('/firefox/')) {
  /!**
   * 对于火狐：若开发者工具开启，则下面这行代码会触发 firefox_test.toString，否则反之
   * 对于谷歌：无论是否开启开发者工具，都会触发
   *!/
  console.log(firefox_test);
}


/!*调试相关的函数重定义*!/
window['console']['log'] = function () {
};

/!*
还有其他检测方法：
    调用链检测
    代理对象（自己暂时没学）
不过上面这两种并不可以简单调用的，所以这里没写
*!/

/!*禁止使用右键*!/

document.onkeydown=function(){

  var e = window.event||arguments[0];


  if(window.event&&window.event.keyCode==123){event.keyCode=0;event.returnValue=false;new Vue({data:function(){this.$notify({title:"你知道的太多了",message:"你非要调试的话试试 Alt+Shift+Fn+F4",position:'bottom-right',offset:50,showClose:true,type:"error"});return{visible:false}}})
    return false;
  }

  if((event.ctrlKey)&&(event.shiftKey)&&(event.keyCode==73)){new Vue({data:function(){this.$notify({title:"你知道的太多了",message:"老弟，好好看电影吧不要瞎调试换哟~",position:'bottom-right',offset:50,showClose:true,type:"error"});return{visible:false}}})
    return false;
  }
  if(e.ctrlKey&&window.event.keyCode==85){new Vue({data:function(){this.$notify({title:"你知道的太多了",message:"老弟，在干嘛呢？好好看电影吧~",position:'bottom-right',offset:50,showClose:true,type:"error"});return{visible:false}}})
    return false;
  }
  if(event.ctrlKey&&window.event.keyCode==83){new Vue({data:function(){this.$notify({title:"你知道的太多了",message:"看电影网页不需要保存哦~",position:'bottom-right',offset:50,showClose:true,type:"error"});return{visible:false}}})
    return false;
  }
}

document.oncontextmenu = function (){new Vue({data:function(){this.$notify({title:"你知道的太多了",message:"复制请用键盘快捷键 Ctrl+C",position:'bottom-right',offset:50,showClose:true,type:"warning"});return{visible:false}}})
  return false;
}

var threshold = 160;
window.setInterval(function() {
  if (window.outerWidth - window.innerWidth > threshold ||
    window.outerHeight - window.innerHeight > threshold) {
    function disableDebugger() {
      debugger;
    }
    $(document).ready(function () {
      disableDebugger();
    });
  }
}, 1e3);*/



console.log(
  `
    █████▒█    ██  ▄████▄   ██ ▄█▀       ██████╗ ██╗   ██╗ ██████╗
 ▓██   ▒ ██  ▓██▒▒██▀ ▀█   ██▄█▒        ██╔══██╗██║   ██║██╔════╝
 ▒████ ░▓██  ▒██░▒▓█    ▄ ▓███▄░        ██████╔╝██║   ██║██║  ███╗
 ░▓█▒  ░▓▓█  ░██░▒▓▓▄ ▄██▒▓██ █▄        ██╔══██╗██║   ██║██║   ██║
 ░▒█░   ▒▒█████▓ ▒ ▓███▀ ░▒██▒ █▄       ██████╔╝╚██████╔╝╚██████╔╝
  ▒ ░   ░▒▓▒ ▒ ▒ ░ ░▒ ▒  ░▒ ▒▒ ▓▒       ╚═════╝  ╚═════╝  ╚═════╝
  ░     ░░▒░ ░ ░   ░  ▒   ░ ░▒ ▒░
  ░ ░    ░░░ ░ ░ ░        ░ ░░ ░
           ░     ░ ░      ░  ░
`
)

/*
console.log(
  `

\t…o888888o.o88888888o.
…8888888888888888888888888o.
…888::::888888888888888888888888o.
…88::::::::88888888888888888888888888o.
…o8:::::::::888::鼎88888888888888888888888.
…8888:8::::::8:::::::::鼎88888888888888888888.
…8888::::8888::::::::::::::::鼎88888888888 ‘’’‘88
…8888:::888888888::::::::::::::::鼎888888888 8
…88.88888888888888888:::::::::::::鼎88888888888
…88…888888888888888888:::::::::::鼎88888888888888
…88.888888888888888888888::::::::鼎88888888888888888
…8…8888888888888888888888::::::鼎88888888888888888888
…8888888888888888::88888:::::鼎8888888888888888888888
…88888888888888888::::8888::::8888888888888888 ‘’’‘8888
…88888888888888888::::8888::::::88888::888888888 888
…88888888888888888::::888:::::鼎::::::888888888 88
…888888888888888888::::88::::::鼎:::::::::::::888888888 88
.8888888888888888888::::8::::::鼎:::::::::::::鼎888888888 88
888…8888888888::88::::8:::::鼎:::8oo8::::::88888888888888 8
88…88888888888:::8:::::::::鼎:::::::::::::88888888888888888
.8…8888888888888:::::::::::鼎::“8888888”:::鼎88888888888888888
…8888888888::888::::::::::鼎:::::“8a8”::::鼎88888888888888888888
.888888888:::::88:::::::::鼎88::::::::::::鼎88888888888888888888888
8888888888:::::8:::::::::鼎88888::::::::鼎888888888888888888888888888
888888888:::::8:::::::::鼎8888888ooooo鼎8888888888888888888888888888888
888888.鼎::::::::::::::鼎8888888888::::::鼎8888888888888888 ‘’’‘888888888
8888…鼎:::::::::::::::鼎88888888888::::::鼎8888888888888888 88888888
.888…鼎::::::::::::::鼎8888888888888:::::::鼎888888888888888 888888
…888.鼎:::::::::::::鼎88888888888888888::::::鼎8888888888888 88888
…88.鼎:::::::::::::8888:88888888888888888:::::鼎鼎888888888 8888
…88.鼎:::::::::::8888鼎::88888::888888888888:::::鼎鼎88888 888
…8…鼎::::::::::8888鼎:::8888:::::88888888888::::::鼎鼎88 888
…8鼎:::::::8888鼎:::::888:::::::88:::8888888:::::::鼎鼎 88
…88鼎:::::::8888鼎::::::88:::::::::8:::::888888::::::::鼎鼎 88
…8888鼎:::::888鼎:::::::::88::::::::::鼎::::8888::::::::::::鼎 8
…88888:鼎::::888鼎::::::::::8:::::::::::鼎::::888::::::::::::::鼎
…88.888:鼎:::888:鼎::::::::::8::::::::::::鼎::888::::::::::::::::鼎
…8.88888鼎:::88::鼎::::::::::::::::::::::::鼎:88::::::::::::::::::鼎
…88888鼎:::88::鼎:::::::::::::::::::::::::鼎88:::::::::::::::::::鼎
…88888鼎:::88::鼎:::::::::::::::::::::::::鼎:8::::::::::::::::::::鼎
…888888鼎::::8::鼎::::::::::::::8888::::::::鼎8::::::::::::8888:::::鼎
…88888…鼎:::8::鼎鼎:::::::::::888888:::::::鼎::::::::::::888888::::鼎
…8888…鼎鼎:::::鼎鼎::::::::::888888:::::::鼎鼎::::::::::888888::::鼎
…8888…鼎:::::::鼎鼎::::::::::8888:::::::鼎鼎鼎::::::::::8888::::鼎’’’’
…888…鼎鼎::::::鼎鼎鼎:::::::::::::::::鼎鼎::鼎::::::::::::::::鼎’’’’
…88…鼎::::::::鼎鼎鼎鼎:::::::::::鼎鼎鼎:::鼎鼎:::::::::::::鼎’’’’
…88…鼎鼎:::::::::鼎鼎鼎鼎鼎鼎鼎鼎鼎鼎:::::::鼎鼎鼎鼎
…88…鼎鼎::::::::::::鼎鼎鼎鼎鼎鼎鼎::::::::::::鼎鼎
…88…8鼎鼎::::::::::::::::::::::::::::::::::::::::鼎
…8…88鼎鼎::::::::::::::::::::::鼎:::鼎::::::::::鼎
…888鼎鼎::::::::::::::::::鼎鼎::::::鼎鼎::::::鼎
…88888鼎鼎:::::::::::::::鼎鼎鼎:::::::鼎鼎:::::鼎
…888888鼎鼎:::::::::::::鼎鼎鼎:::::::::鼎鼎:::鼎
…88888888鼎鼎:::::::::::鼎鼎鼎:::::::::::鼎:::鼎
…88.8888888.鼎::::::::鼎鼎鼎::::::::::::::鼎::鼎
…8…888888…鼎::::::鼎鼎:::::::::::::::::鼎::鼎
…888888…鼎:::::鼎:::::::::::::::::::鼎::鼎鼎
…888888…鼎::::鼎::::::::::::::::::::::::鼎鼎
…888888…鼎::::鼎::::::::::::::::::::::::鼎:鼎
…88888…鼎::::::::::::::8::::::::::::::::鼎::鼎
…88888…鼎:::::::::::::::88::::::::::::::::鼎::鼎
…88888…鼎::::::::::::::::8::::::::::::::::::鼎::鼎
…88888…鼎:::::鼎:::::::::::::::::::::鼎::::::::鼎::鼎
…8888…鼎:::::鼎:::::::::::::::::::::::鼎鼎::::::鼎::鼎
…8888…鼎:::::鼎:::::::::::::::::::::::鼎鼎鼎:::::::鼎:鼎
…888…鼎:::::鼎鼎::::::::::::::::::::::鼎鼎鼎::::::::鼎:鼎
…鼎::::::鼎鼎:::::::::::::::::::::鼎鼎鼎::::::::: 鼎:鼎
…鼎:::::鼎:::::::::::::::::::::::鼎鼎::::::::::::::鼎:鼎
…鼎::::::::::::::::::::::::::::::鼎::::::::::::::::::鼎:鼎
…鼎::::::::::::::::::::::::::::::::::::::::::::::::::鼎:鼎
…鼎::::::::鼎:::::::::::::::::::::::::::::::::::::::::鼎:鼎
…鼎::::::::鼎:::::::::::::::::鼎::::::::::::::::::::::鼎:鼎
…鼎::::::::::鼎::::::::::::::鼎::::::::::::::::::::::::鼎:鼎鼎
…鼎:::::::::::鼎8::::::::8:鼎:::::::::::::::::::::::::鼎:鼎鼎p
…鼎::::::::::::888hole888鼎:::::::::::::::::::::::::::鼎:鼎o
…鼎:::::::::::::88888888鼎:::::::::::::::::::::::::::鼎:鼎p
…鼎::::::::::::::888888鼎::::::::::::::::::::::::::::鼎:鼎
…鼎:::::::::::::::88888鼎:::::::::::::::::::::::::::鼎:鼎
…鼎:::::::::::::::::88鼎::::::::::::::::::::::::::::鼎鼎
…鼎:::::::::::::::::::鼎:::::::::::::::::::::::::::鼎鼎
…鼎:::::::::::::::::::鼎::::::::::::::::::::::::::鼎鼎
…鼎:::::::::::::::::鼎::::::::::::::::::::::::::鼎鼎
…鼎:::::::::::::::::鼎::::::::::::::::::::::::::鼎
…鼎:::::::::::::::鼎::::::::::::::::::::::::::鼎
…鼎:::::::::::::::鼎:::::::::::::::::::::::::鼎
…鼎:::::::::::::鼎:::::::::::::::::::::::::鼎
…鼎:::::::::::::鼎:::::::::::::::::::::::鼎
…鼎:::::::::::鼎:::::::::::::::::::::::鼎
…鼎:::::::::::鼎::::::::::::::::::::::鼎
…鼎:::::::::鼎::::::::::::::::::::::鼎
…鼎:::::::::鼎:::::::::::::::::::::鼎
…鼎:::::::鼎:::::::::::::::::::::鼎
…鼎::::::鼎::::::::::::::::::::鼎
…鼎:::::鼎::::::::::::::::::::鼎
…鼎:::鼎::::::::::::::::::::鼎
…鼎::鼎::::::::::::::::::::鼎
…鼎:鼎:::::::::::::::::::鼎
…鼎鼎:::::::::::::::::::鼎
…鼎鼎::::::::::::::::::鼎
…鼎鼎:::::::::::::::::鼎
…鼎鼎:::::鼎::::::::鼎鼎
…鼎鼎::::鼎鼎:::::::鼎鼎
…鼎鼎::::::鼎鼎:::::鼎鼎
…鼎鼎::::::::::::::鼎鼎
…鼎鼎::::鼎:::::::::鼎鼎
…鼎鼎::鼎鼎鼎:::::::鼎鼎
…鼎鼎::鼎鼎鼎:::::::鼎鼎
…鼎鼎:::鼎鼎::::::::::鼎
…鼎鼎::鼎鼎::::::::::鼎
…鼎鼎::鼎鼎:::::::::::鼎
…鼎鼎:::鼎::::::::::::鼎
…鼎鼎:::::::::::::::::鼎
…鼎鼎:::::::::::::::::鼎
…鼎鼎::::::::::::::::::鼎
…鼎鼎::::::::::::::::::鼎
…鼎鼎::::::::::::::::::鼎
…鼎鼎:::::::::::::::::鼎
…鼎鼎:::::::::::::::::鼎
…鼎鼎:::::::::::::::::鼎
…鼎鼎:::::::::::::::::鼎
…鼎鼎:::::::::::::::::鼎
…鼎鼎:::::::::::::::鼎
…鼎鼎:::::::::::::::鼎
…鼎鼎:::::::::::::::鼎
…鼎鼎::::::::::::::鼎
…鼎鼎:::::::::::::鼎
…鼎鼎:::::::::::::鼎
…鼎鼎:::::::::::::鼎
…鼎鼎::::::::::::鼎
…鼎鼎::::::::::::鼎
…鼎鼎:::::::::::鼎
…鼎鼎::::::::::鼎
…鼎鼎::::::::::鼎
…鼎鼎::::::::鼎
…鼎鼎::::::::鼎
…鼎鼎::::::::鼎
…鼎鼎:::::::鼎
…鼎鼎:::::::鼎
…鼎鼎::::::鼎
…鼎鼎::::::鼎
…鼎鼎::::::鼎
…鼎鼎::::::鼎
…鼎鼎::::::鼎
…鼎鼎::::::鼎
…鼎鼎:::::::鼎
…鼎:鼎::::::::鼎
…鼎:鼎:::::::::鼎
…鼎:鼎::::::::::鼎
…鼎:鼎:::::::::::鼎
…鼎:鼎::::::::::::鼎
…鼎:鼎:::::::::::::鼎
…鼎:鼎::::::::::::::鼎
…鼎鼎:::::::::::::::::鼎
…鼎鼎::::::::::::::::鼎
…鼎鼎鼎:::::::::鼎鼎
…鼎鼎::鼎::::鼎鼎
…鼎鼎鼎鼎鼎鼎鼎
…鼎鼎鼎鼎鼎

`)
console.log(
  `
 * ░░░░░░░░░░░░░░░░░░░░░░░░▄░░
 * ░░░░░░░░░▐█░░░░░░░░░░░▄▀▒▌░
 * ░░░░░░░░▐▀▒█░░░░░░░░▄▀▒▒▒▐
 * ░░░░░░░▐▄▀▒▒▀▀▀▀▄▄▄▀▒▒▒▒▒▐
 * ░░░░░▄▄▀▒░▒▒▒▒▒▒▒▒▒█▒▒▄█▒▐
 * ░░░▄▀▒▒▒░░░▒▒▒░░░▒▒▒▀██▀▒▌
 * ░░▐▒▒▒▄▄▒▒▒▒░░░▒▒▒▒▒▒▒▀▄▒▒
 * ░░▌░░▌█▀▒▒▒▒▒▄▀█▄▒▒▒▒▒▒▒█▒▐
 * ░▐░░░▒▒▒▒▒▒▒▒▌██▀▒▒░░░▒▒▒▀▄
 * ░▌░▒▄██▄▒▒▒▒▒▒▒▒▒░░░░░░▒▒▒▒
 * ▀▒▀▐▄█▄█▌▄░▀▒▒░░░░░░░░░░▒▒▒
 * 修勾就这样默默地看着你~
////////////////////////////////////////////////////////////
//                                                       //
//            版权所有: 小淘汰的私生活                      //
//            官方网站: https://eean.cn                  //
//                                                     //
////////////////////////////////////////////////////////
  `
)
*/
